.nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 50px;
    height: 650px;
    z-index: 1;

    /* Scroll Animation*/
    transition-timing-function: ease-in;
    transition: all 0.5s;

}

.nav__red {
    background-color: darkred;
}

.nav__logo {
    position: fixed;
    left: 60px;
    width: 120px;
    object-fit: contain;
    margin-right: 40px;
}

.nav__logo:hover {
    transition: all 0.4s;
    transform: scale(1.04);
}


.nav__avatar {
    position: fixed;
    right: 80px;
    width: auto;
    object-fit: contain;
    color: white;
}

.nav__login {
    position: relative;
    right: 60px;
    width: 30px;
    object-fit: contain;
    color: white;
}

.nav__register {
    position: relative;
    right: 40px;
    width: 30px;
    object-fit: contain;
    color: white;
}
.nav__avatar:hover {
    color: red;
    transform: scale(1.25);
    transition: all 0.2s;
}