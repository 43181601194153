.carousel {
    color: white;
    object-fit: contain;
    position: relative;
    height: 550px;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.carousel__poster {
    object-fit: fill;
    width: 500px;
    max-height: 550px;
    border-radius: 15px;
    box-sizing: "border-box";
    transition: transform 450ms;
}