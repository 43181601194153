.row {
    color: white;
    margin-left: 20px;
}

.row__posters {
    display:flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.button__posters {
    display:flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 40px;
}

.row__icon {
    display:flex;
}

.row__posters::-webkit-scrollbar {
    display: none;
}

.button__posters::-webkit-scrollbar {
    display: none;
}

.row__poster {
    object-fit: contain;
    width: auto;
    max-height: 250px;
    margin-right: 10px;
    border-radius: 18px;
    padding: 5px;
    transition: transform 450ms;
}

.row__poster:hover {
    transform: scale(1.06);
}

.row__posterLarge {
    max-height: 350px;
}

.row__posterLarge:hover {
    transform: scale(1.09);
}

.actor__poster {
    object-fit: contain;
    width: auto;
    max-height: 550px;
    margin-right: 10px;
    border-radius: 30px;
    padding: 10px;
    transition: transform 450ms;
    background-color: beige;
}

.actor__poster:hover {
    transform: scale(1.06);
}

.actor__posterLarge {
    max-height: 350px;
}

.actor__posterLarge:hover {
    transform: scale(1.09);
}

.button__poster {
    border-radius: 10 - 3.0;
    padding: 10 - 1.0;
    align-items: 'center';
    justify-content: 'center';
    margin-right: 10 + 2.0;
    object-fit: contain;
}

.button__poster:hover {
    transform: scale(1.06);
}

.button__posterLarge {
    max-height: 350px;
}

.button__posterLarge:hover {
    transform: scale(1.09);
}

.Demo__container {
    margin: 10px;
}

.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
  }
  
.Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
  }
  
.Demo__some-network__share-button {
    cursor: pointer;
  }